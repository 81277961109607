import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/loader/Loadable';
/****Layouts*****/

const FullLayout = Loadable(lazy(() => import('../layouts/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/BlankLayout')));
/***** Pages ****/
const Accueil = Loadable(lazy(() => import('../views/Accueil')));

const Patient = Loadable(lazy(() => import('../views/pages/PatientDetails')));
const Patients = Loadable(lazy(() => import('../views/pages/Patients')));

const Medications = Loadable(lazy(() => import('../views/pages/Medications')));

const Users = Loadable(lazy(() => import('../views/pages/Users')));
const DevisDetails = Loadable(lazy(() => import('../views/pages/DevisDetails')));
const Devis = Loadable(lazy(() => import('../views/pages/Devis')));
const FactureDetails = Loadable(lazy(() => import('../views/pages/FactureDetails')));
const Factures = Loadable(lazy(() => import('../views/pages/Factures')));

const Soins = Loadable(lazy(() => import('../views/pages/Soins')));

const Pathologies = Loadable(lazy(() => import('../views/pages/Pathologies')));

const Titles = Loadable(lazy(() => import('../views/ref/Titles')));
const Countries = Loadable(lazy(() => import('../views/ref/Countries')));
const Cities = Loadable(lazy(() => import('../views/ref/Cities')));
/*
const MaritalStatuses = Loadable(lazy(() => import('../views/ref/MaritalStatuses')));
const FamilyRelationship = Loadable(lazy(() => import('../views/ref/FamilyRelationship')));
const PaymentMethods = Loadable(lazy(() => import('../views/ref/PaymentMethods')));

const Titles = Loadable(lazy(() => import('../views/ref/Titles')));
const Countries = Loadable(lazy(() => import('../views/ref/Countries')));
const Cities = Loadable(lazy(() => import('../views/ref/Cities')));
/*
const MaritalStatuses = Loadable(lazy(() => import('../views/ref/MaritalStatuses')));
const FamilyRelationship = Loadable(lazy(() => import('../views/ref/FamilyRelationship')));
const PaymentMethods = Loadable(lazy(() => import('../views/ref/PaymentMethods')));

*/

/***** Auth Pages ****/
const Error = Loadable(lazy(() => import('../views/auth/Error')));
const RegisterFormik = Loadable(lazy(() => import('../views/auth/RegisterFormik')));
const LoginFormik = Loadable(lazy(() => import('../views/auth/LoginFormik')));
const Maintanance = Loadable(lazy(() => import('../views/auth/Maintanance')));
const LockScreen = Loadable(lazy(() => import('../views/auth/LockScreen')));
const RecoverPassword = Loadable(lazy(() => import('../views/auth/RecoverPassword')));

/*****Routes******/

const ThemeRoutes = [
  {
    path: '/',
    element: <FullLayout />,
    children: [
      { path: '/', name: 'Accueil', exact: true, element: <Accueil /> },

      { path: '/patients/:id', name: 'PatientDetails', exact: true, element: <Patient /> },
      { path: '/patients', name: 'Patients', exact: true, element: <Patients /> },

      { path: '/medications', name: 'Medications', exact: true, element: <Medications /> },

      { path: '/users', name: 'Personnel', exact: true, element: <Users /> },

      { path: '/devis/:id', name: 'DevisDetails', exact: true, element: <DevisDetails /> },
      { path: '/devis', name: 'Devis', exact: true, element: <Devis /> },
      { path: '/devis/:currentPatient/patient', name: 'DevisPatient', exact: true, element: <Devis /> },

      { path: '/factures/:id', name: 'FactureDetails', exact: true, element: <FactureDetails /> },
      { path: '/factures', name: 'Factures', exact: true, element: <Factures /> },
      { path: '/factures/:currentPatient/patient', name: 'FacturesPatient', exact: true, element: <Factures /> },

      { path: '/soins', name: 'Soins', exact: true, element: <Soins /> },

      { path: '/pathologies', name: 'Pathologies', exact: true, element: <Pathologies /> },
      
      { path: '/civilites', name: 'Civilites', exact: true, element: <Titles /> },
      { path: '/pays', name: 'Pays', exact: true, element: <Countries /> },
      { path: '/villes', name: 'Villes', exact: true, element: <Cities /> },
      /*
      { path: '/situations-matrimoniales', name: 'Situations matrimoniales', exact: true, element: <MaritalStatuses /> },
      { path: '/liens-familiaux', name: 'Liens familiaux', exact: true, element: <FamilyRelationship /> },
      { path: '/methodes-payment', name: 'Méthode de paiement', exact: true, element: <PaymentMethods /> },
      */
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: '/auth',
    element: <BlankLayout />,
    children: [
      { path: '404', element: <Error /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
      { path: 'registerformik', element: <RegisterFormik /> },
      { path: 'loginformik', element: <LoginFormik /> },
      { path: 'maintanance', element: <Maintanance /> },
      { path: 'lockscreen', element: <LockScreen /> },
      { path: 'recoverpwd', element: <RecoverPassword /> },
    ],
  },
];

export default ThemeRoutes;
